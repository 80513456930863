import React from "react";

function PrimaryLinks() {
    return (
        <section>
            <header className="major">
                <h2>Erat lacinia</h2>
            </header>
            <div className="features">
                <article>
                    <span className="icon fa-gem"></span>
                    <div className="content">
                        <h3>Portitor ullamcorper</h3>
                        <p>Aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore. Proin aliquam facilisis ante interdum. Sed nulla amet lorem feugiat tempus aliquam.</p>
                    </div>
                </article>
                <article>
                    <span className="icon solid fa-paper-plane"></span>
                    <div className="content">
                        <h3>Sapien veroeros</h3>
                        <p>Aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore. Proin aliquam facilisis ante interdum. Sed nulla amet lorem feugiat tempus aliquam.</p>
                    </div>
                </article>
                <article>
                    <span className="icon solid fa-rocket"></span>
                    <div className="content">
                        <h3>Quam lorem ipsum</h3>
                        <p>Aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore. Proin aliquam facilisis ante interdum. Sed nulla amet lorem feugiat tempus aliquam.</p>
                    </div>
                </article>
                <article>
                    <span className="icon solid fa-signal"></span>
                    <div className="content">
                        <h3>Sed magna finibus</h3>
                        <p>Aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore. Proin aliquam facilisis ante interdum. Sed nulla amet lorem feugiat tempus aliquam.</p>
                    </div>
                </article>
            </div>
        </section>
    );
}

export default PrimaryLinks;