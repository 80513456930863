import React from 'react';
import WorldCupTopScorers from '../components/projects/accumulators/worldcup-topscorers'
import EuroTopScorers from '../components/projects/accumulators/euro-topscorers'

function GoalScorers(props) {
    var isWorldCup = props.isWorldCup;
    return (
        <div className="page">
            {isWorldCup ? <WorldCupTopScorers /> : <EuroTopScorers />}            
        </div>
    );
}

export default GoalScorers;