import React, { useEffect, useState } from "react";
import getAppSetting from "../appsettings/appsettingsHelper";
//import { DotenvAzure } from "dotenv-azure";

function HomePageIntro() {    
    const { config: testAppSetting } = getAppSetting('TestAppSetting');
    const { NODE_ENV } = process.env;
    const { LOCAL_VAR } = process.env;
    const [ apiText, setApiText ] = useState('');
    const [ dotenvAz, setdotenvAz ] = useState(null);    

    useEffect(() => {
        (async function () {
            //const { text } = await( await fetch('/api/message')).json();
            //console.log('api: ' + text);
            //setApiText(text);

            //const dotenvAzure = new DotenvAzure();
            //const { parsed } = await dotenvAzure.config();
            //setdotenvAz(parsed);
        })();
    }, []);

    // dotenv
    // https://trekinbami.medium.com/using-environment-variables-in-react-6b0a99d83cf5
    
    // app config
    // https://medium.com/bb-tutorials-and-thoughts/react-configuring-environment-variables-in-azure-static-web-apps-service-699eaf0b5e60

    // feature flags & app config
    // https://towardsdatascience.com/lets-build-feature-flag-hooks-for-react-azure-app-configuration-bc7417f24e94

    // these examples appear to either read from env specific local files or from azure using different environment settings
    // want to achieve local settings from file, overridden by azure

    // https://www.gimtec.io/articles/process-is-not-defined/

    // works with webpack: https://cstroman.medium.com/using-environment-variables-with-react-webpack-c7a04bbf833e

    // function api ext: https://learn.microsoft.com/en-gb/azure/static-web-apps/add-api?tabs=vanilla-javascript
    // swa start dist --api-location api
    // works locally.  don't think function app api's are supported on free static web app hosting

    // https://www.npmjs.com/package/dotenv-azure

    /*
    seemingly app settings from azure static web apps not supported due to security reasons
    recommends using key vault or azure configuration instead
    dotenv-webpack npm package works, but azure appsettings doesn't override
    dev vs prod .env files could be used but defeats the object as want .env values locally & appsettings in cloud
    static func apps just return static content via http triggers, same for all envs
    dotenv-azure sounds like it would do what i want but can't get it to work with webpack.  dotenv-webpack just seemlessly works with webpack
    */
    return (
        <section id="banner">
            <div className="content">
                <header>
                    <h1>Hi, I’m Editorial<br />
                        by HTML5 UP</h1>
                    <p>App setting value: {testAppSetting}</p>                    
                    <p>Env value: {NODE_ENV}</p>                    
                    <p>AppSetting Test: {process.env.MY_TEST_APPSETTING2}</p>
                    <p>yml Test: {process.env.mytestsetting}</p>
                    <p>api Test: {apiText && apiText}</p>
                    <p>LocalVar Test: {process.env.LOCAL_VAR}</p>
                    <p>LocalVar2 Test: {LOCAL_VAR}</p>
                    {/*<p>DotenvAzure Test: {dotenvAz}</p>*/}
                </header>
                <p>Aenean ornare velit lacus, ac varius enim ullamcorper eu. Proin aliquam facilisis ante interdum congue. Integer mollis, nisl amet convallis, porttitor magna ullamcorper, amet egestas mauris. Ut magna finibus nisi nec lacinia. Nam maximus erat id euismod egestas. Pellentesque sapien ac quam. Lorem ipsum dolor sit nullam.</p>
                <ul className="actions">
                    <li><a href="#" className="button big">Learn More</a></li>
                </ul>
            </div>
            <span className="image object">
                <img src="images/pic10.jpg" alt="" />
            </span>
        </section>
    );
}

export default HomePageIntro;