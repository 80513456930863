import { AppConfigurationClient } from '@azure/app-configuration';
import { useMemo, useState } from 'react';

const client = new AppConfigurationClient('Endpoint=https://davidbuckell-appconfiguration.azconfig.io;Id=GK74-lo-s0:wNsiuMvy8wqVBXUrrvcd;Secret=Bu0wIqESZFs+2Sk4cm5IMNlt53LXgXfJJy3sVD0DdFM=');

const getAppSetting = (configKey = '') => {
    const [config, setConfig] = useState('');

    useMemo(async () => {
        if (!configKey || !configKey.toString().trim().length) {
            console.error('Missing config key');
        }
        else {
            try {
                const result = await client.getConfigurationSetting({key: configKey.toString().trim()});
                if (result) {
                    console.log(`Retrieved appsetting key '${configKey}' with value '${result.value}'`);
                    setConfig(result.value);
                }
            } catch (error) {
                console.error(error);
            }
        }
    }, [configKey])

    
    return { config };
}

export default getAppSetting;