import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../../css/topscorers.css';

const EuroTopScorers = () => {
    const [topScorers, setTopScorers] = useState();
    const [selectedTeam, setSelectedTeam] = useState();

    function selectedTeamChanged(e)
    {
        setSelectedTeam(e.target.value);
        const apiUrl = 'https://api.davidbuckell.com/api/topscorersdata?competition=' + e.target.value;
        axios.get(apiUrl).then((result) => {
            var data = result.data;
            if (data && data.scorers)
            {
                setTopScorers(data.scorers);
            }
            else
            {
                setTopScorers([{scorer: "", goals: 0}]);
            }                        
        });
    }

    function addPlayer(e) {
        var newPlayer = {"scorer":'', "goals": 0};
        setTopScorers(topScorers => [...topScorers, newPlayer]);
    }

    function scorerChanged(e, index) {
        const scorers = [...topScorers];
        scorers[index].scorer = e.target.value;
        setTopScorers(scorers);
    }

    function goalsChanged(e, index) {        
        const scorers = [...topScorers];
        scorers[index].goals = parseInt(e.target.value);
        setTopScorers(scorers);
    }

    function removeScorer(e, index) {        
        const scorers = [...topScorers];
        scorers.splice(index, 1);
        setTopScorers(scorers);
    }

    function submitScorers(e) {
        e.preventDefault();
        const data = {
            "Competition":selectedTeam,
            "Scorers":topScorers
        };
        const apiUrl = 'https://api.davidbuckell.com/api/topscorersdata';
        axios.post(apiUrl, data, { 
            headers: {
            'Content-Type': 'application/json',
            } 
        });
    }

    return (
        <React.Fragment>
            <article id="topscorers">
                <div className="col-12">
                    <select id="competition" onChange={selectedTeamChanged}>
                        <option value="Default">Select a team...</option>
                        <option value="AlbaniaEuropeanChampionship">Albania Top Scorers</option>
                        <option value="AustriaEuropeanChampionship">Austria Top Scorers</option>
                        <option value="BelgiumEuropeanChampionship">Belgium Top Scorers</option>
                        <option value="CroatiaEuropeanChampionship">Croatia Top Scorers</option>
                        <option value="CzechRepublicEuropeanChampionship">Czech Republic Top Scorers</option>
                        <option value="DenmarkEuropeanChampionship">Denmark Top Scorers</option>
                        <option value="EnglandEuropeanChampionship">England Top Scorers</option>
                        <option value="FranceEuropeanChampionship">France Top Scorers</option>
                        <option value="GeorgiaEuropeanChampionship">Georgia Top Scorers</option>
                        <option value="GermanyEuropeanChampionship">Germany Top Scorers</option>
                        <option value="HungaryEuropeanChampionship">Hungary Top Scorers</option>
                        <option value="ItalyEuropeanChampionship">Italy Top Scorers</option>
                        <option value="NetherlandsEuropeanChampionship">Netherlands Top Scorers</option>
                        <option value="PolandEuropeanChampionship">Poland Top Scorers</option>
                        <option value="PortugalEuropeanChampionship">Portugal Top Scorers</option>
                        <option value="RomaniaEuropeanChampionship">Romania Top Scorers</option>
                        <option value="ScotlandEuropeanChampionship">Scotland Top Scorers</option>
                        <option value="SerbiaEuropeanChampionship">Serbia Top Scorers</option>
                        <option value="SlovakiaEuropeanChampionship">Slovakia Top Scorers</option>
                        <option value="SloveniaEuropeanChampionship">Slovenia Top Scorers</option>
                        <option value="SpainEuropeanChampionship">Spain Top Scorers</option>
                        <option value="SwitzerlandEuropeanChampionship">Switzerland Top Scorers</option>
                        <option value="TurkeyEuropeanChampionship">Turkey Top Scorers</option>
                        <option value="UkraineEuropeanChampionship">Ukraine Top Scorers</option>
                    </select>
                </div>

                <br/>

                <form onSubmit={submitScorers}>
                    <div className="row gtr-uniform">
                        {topScorers && topScorers.map((scorer, index) => (
                        <React.Fragment key={scorer.scorer}>
                            <div className="col-8">
                                <input className="player" type="text" name={index} defaultValue={scorer.scorer} onBlur={(e) => scorerChanged(e, index)}/>
                            </div>
                            <div className="col-3">
                                <input className="goals" type="text" name={index} defaultValue={scorer.goals} onBlur={(e) => goalsChanged(e, index)}/>
                            </div>
                            <div className="col-1">
                                <button className="button primary" onClick={(e) => removeScorer(e, index)}>X</button>
                            </div>
                        </React.Fragment>
                        ))}
                        
                        <div className="col-12" key={`{index}-div`}>
                            <ul className="actions">
                                <li><input type="button" value="Add Player" onClick={addPlayer} /></li>
                                <li><input type="submit" value="Submit" className="primary"/></li>
                            </ul>
                        </div>
                    </div>
                </form>
            </article>
        </React.Fragment>
    );
}

export default EuroTopScorers;
