import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../../css/topscorers.css';

const WorldCupTopScorers = () => {
    const [topScorers, setTopScorers] = useState();
    const [selectedTeam, setSelectedTeam] = useState();

    function selectedTeamChanged(e)
    {
        setSelectedTeam(e.target.value);
        const apiUrl = 'https://api.davidbuckell.com/api/topscorersdata?competition=' + e.target.value;
        axios.get(apiUrl).then((result) => {
            var data = result.data;
            if (data && data.scorers)
            {
                setTopScorers(data.scorers);
            }
            else
            {
                setTopScorers([{scorer: "", goals: 0}]);
            }                        
        });
    }

    function addPlayer(e) {
        var newPlayer = {"scorer":'', "goals": 0};
        setTopScorers(topScorers => [...topScorers, newPlayer]);
    }

    function scorerChanged(e, index) {
        const scorers = [...topScorers];
        scorers[index].scorer = e.target.value;
        setTopScorers(scorers);
    }

    function goalsChanged(e, index) {        
        const scorers = [...topScorers];
        scorers[index].goals = parseInt(e.target.value);
        setTopScorers(scorers);
    }

    function removeScorer(e, index) {        
        const scorers = [...topScorers];
        scorers.splice(index, 1);
        setTopScorers(scorers);
    }

    function submitScorers(e) {
        e.preventDefault();
        const data = {
            "Competition":selectedTeam,
            "Scorers":topScorers
        };
        const apiUrl = 'https://api.davidbuckell.com/api/topscorersdata';
        axios.post(apiUrl, data, { 
            headers: {
            'Content-Type': 'application/json',
            } 
        });
    }

    return (
        <React.Fragment>
            <article id="topscorers">
                <div className="col-12">
                    <select id="competition" onChange={selectedTeamChanged}>
                        <option value="Default">Select a team...</option>
                        <option value="ArgentinaWorldCup">Argentina Top Scorers</option>
                        <option value="AustraliaWorldCup">Australia Top Scorers</option>
                        <option value="BelgiumWorldCup">Belgium Top Scorers</option>
                        <option value="BrazilWorldCup">Brazil Top Scorers</option>
                        <option value="CameroonWorldCup">Cameroon Top Scorers</option>
                        <option value="CanadaWorldCup">Canada Top Scorers</option>
                        <option value="CostaRicaWorldCup">Costa Rica Top Scorers</option>
                        <option value="CroatiaWorldCup">Croatia Top Scorers</option>
                        <option value="DenmarkWorldCup">Denmark Top Scorers</option>
                        <option value="EcuadorWorldCup">Ecuador Top Scorers</option>
                        <option value="EnglandWorldCup">England Top Scorers</option>
                        <option value="FranceWorldCup">France Top Scorers</option>
                        <option value="GermanyWorldCup">Germany Top Scorers</option>
                        <option value="GhanaWorldCup">Ghana Top Scorers</option>
                        <option value="IranWorldCup">Iran Top Scorers</option>
                        <option value="JapanWorldCup">Japan Top Scorers</option>
                        <option value="MexicoWorldCup">Mexico Top Scorers</option>
                        <option value="MoroccoWorldCup">Morocco Top Scorers</option>
                        <option value="NetherlandsWorldCup">Netherlands Top Scorers</option>
                        <option value="PolandWorldCup">Poland Top Scorers</option>
                        <option value="PortugalWorldCup">Portugal Top Scorers</option>
                        <option value="QatarWorldCup">Qatar Top Scorers</option>
                        <option value="SaudiArabiaWorldCup">Saudi Arabia Top Scorers</option>
                        <option value="SenegalWorldCup">Senegal Top Scorers</option>
                        <option value="SerbiaWorldCup">Serbia Top Scorers</option>
                        <option value="SouthKoreaWorldCup">South Korea Top Scorers</option>
                        <option value="SpainWorldCup">Spain Top Scorers</option>                        
                        <option value="SwitzerlandWorldCup">Switzerland Top Scorers</option>
                        <option value="TunisiaWorldCup">Tunisia Top Scorers</option>
                        <option value="UruguayWorldCup">Uruguay Top Scorers</option>
                        <option value="UsaWorldCup">USA Top Scorers</option>
                        <option value="WalesWorldCup">Wales Top Scorers</option>
                    </select>
                </div>

                <br/>

                <form onSubmit={submitScorers}>
                    <div className="row gtr-uniform">
                        {topScorers && topScorers.map((scorer, index) => (
                        <React.Fragment key={scorer.scorer}>
                            <div className="col-8">
                                <input className="player" type="text" name={index} defaultValue={scorer.scorer} onBlur={(e) => scorerChanged(e, index)}/>
                            </div>
                            <div className="col-3">
                                <input className="goals" type="text" name={index} defaultValue={scorer.goals} onBlur={(e) => goalsChanged(e, index)}/>
                            </div>
                            <div className="col-1">
                                <button className="button primary" onClick={(e) => removeScorer(e, index)}>X</button>
                            </div>
                        </React.Fragment>
                        ))}
                        
                        <div className="col-12" key={`{index}-div`}>
                            <ul className="actions">
                                <li><input type="button" value="Add Player" onClick={addPlayer} /></li>
                                <li><input type="submit" value="Submit" className="primary"/></li>
                            </ul>
                        </div>
                    </div>
                </form>
            </article>
        </React.Fragment>
    );
}

export default WorldCupTopScorers;
